import { render, staticRenderFns } from "./history.vue?vue&type=template&id=954f62b4&"
import script from "./history.vue?vue&type=script&lang=js&"
export * from "./history.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VFabTransition } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTimeline } from 'vuetify/lib'
import { VTimelineItem } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VFabTransition,VFlex,VIcon,VLayout,VSpacer,VTimeline,VTimelineItem,VTooltip})
